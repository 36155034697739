import React, { useContext, useEffect, useState } from 'react';

import { Breadcrumb, ConfigProvider, Layout, FloatButton, Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AndroidFilled, AppleFilled } from '@ant-design/icons';

import { ApiCall } from '../helpers/ApiCall';
import { Context } from '../context';

const { Header, Content } = Layout;

function AppLayout({children, pageTitle}) {

  const {
    appButtonContentColor,
    appLogoHorizontal,
    appSecondaryColor,
    appPrimaryColor,
    topBarTextColor,
    appButtonColor,  
    linkAndroid,  
    deviceType,
    linkIos,
    baseUrl,
  } = useContext(Context);

  useEffect(()=>{
    
      //applico delle modifiche ai colori
      document.querySelector(':root').style.setProperty('--buttonContentColor', appButtonContentColor);
      document.querySelector(':root').style.setProperty('--secondary', appSecondaryColor);
      document.querySelector(':root').style.setProperty('--buttonColor', appButtonColor);
      document.querySelector(':root').style.setProperty('--primary', appPrimaryColor);
      document.querySelector(':root').style.setProperty('--topBarTextColor', topBarTextColor);

  },[appPrimaryColor])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary:appPrimaryColor,
        },
      }}
    >
        <Layout className="layout">
          <Header
            style={{
              display: 'flex',
              alignItems: 'center',
              background:appPrimaryColor
            }}
            className='p-0'
          >
            <div className="logo h-100 p-10 w-100">
              <img src={baseUrl + appLogoHorizontal} className='h-100'/>
            </div>
            <div className='page-title'>
            {pageTitle}
            </div>
          </Header>
          <Content>
            {children}
          </Content>
          <a href={deviceType !== "iPhone" && deviceType !== "iPad" && deviceType !== "Macintosh" ? linkAndroid : linkIos} target='_blank'>
            <Button
              type='primary'
              size='large'
              className='float-btn bold'
              shape='round'
              icon={deviceType !== "iPhone" && deviceType !== "iPad" && deviceType !== "Macintosh"? <AndroidFilled/> : <AppleFilled/>}
            >
              Scarica l'App
            </Button>
          </a>
        </Layout>
    </ConfigProvider>
  );
}

export default AppLayout;