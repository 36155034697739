
import { useEffect, useState } from 'react';

import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { Spin } from 'antd';

import { ApiCall } from './helpers/ApiCall';
import SmartCard from './pages/SmartCard';
import Register from './pages/Register';
import Page404 from './components/404';
import { Context } from './context';
import Login from './pages/Login';


import './App.scss';

const urlParams = new URLSearchParams(window.location.search);

const usePassword = urlParams.get('password');
const appReference = urlParams.get('app');
const webHook = urlParams.get('webhook');

const userAgentData = window.navigator.userAgent;
const deviceType = userAgentData.split("(")[1].split(";")[0];


function App() {

  const [reference, setReference] = useState(appReference ? appReference : sessionStorage.getItem("__smart_card_reference"));
  const [referral, setReferral] = useState("");
  const [render, setRender] = useState(false);  
  const [error, setError] = useState(false);
  
  //variabili dati app
  const [smsActivation, setSmsActivation] = useState("0");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [linkAndroid, setLinkAndroid] = useState("");
  const [hideFields, setHideFields] = useState("");
  const [appToken, setAppToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [appName, setAppName] = useState("");
  const [linkIos, setLinkIos] = useState("");

  //variabili grafiche app
  const [appButtonContentColor, setAppButtonContentColor] = useState("");
  const [appBackgroundColor, setAppBackgroundColor] = useState("");
  const [appLogoHorizontal, setAppLogoHorizontal] = useState("");
  const [appSecondaryColor, setAppSecondaryColor] = useState("");
  const [appPrimaryColor, setAppPrimaryColor] = useState("");
  const [topBarTextColor, setTopBarTextColor] = useState("");
  const [appButtonColor, setAppButtonColor] = useState("");
  const [appLogo, setAppLogo] = useState("");

  //variabili dati pagine
  const [registerPage, setRegisterPage] = useState({});
  const [loginPage, setLoginPage] = useState({});
  const [cardPage, setCardPage] = useState({});

  useEffect(()=>{

    if(webHook){
      let decodedWebhook = base64_decode(webHook)
      if(decodedWebhook.includes("?")){
        decodedWebhook = decodedWebhook + "&"
      }else{
        decodedWebhook = decodedWebhook + "?"
      };
      const encodedWebhook = base64_encode(decodedWebhook);
      setReferral(encodedWebhook);
    };

    if(reference){

      sessionStorage.setItem("__smart_card_reference", reference);

      (async ()=>{
        const res = await ApiCall(`get_app_by_reference/?{"reference":"${reference}"}`);
        
        if(!res.status){

          //setto i dati app
          setLinkAndroid("https://play.google.com/store/apps/details?id=" + res.id_app_android);
          setLinkIos("https://apps.apple.com/it/app/" + res.id_app_ios);
          setSmsActivation(res.mobyt_sms_activation);
          setPrivacyPolicy(res.privacy_policy);
          setAppToken(res.token_key);
          setBaseUrl(res.base_url);
          //setto i dati grafici
          setAppButtonContentColor(res.button_content_color);
          setAppBackgroundColor(res.background_color);
          setTopBarTextColor(res.top_bar_text_color);
          setAppLogoHorizontal(res.logo_horizontal);
          setAppSecondaryColor(res.secondary_color);
          setAppPrimaryColor(res.primary_color);
          setHideFields(res.hide_signup_fields);
          setAppButtonColor(res.button_color);
          setAppName(res.name);
          setAppLogo(res.logo);

          //setto i meta dati dell'app

          document.title = res.name + " - SmartCard";

          var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/png';
          link.rel = 'shortcut icon';
          link.href = `${res.base_url + res.icon}`;
          document.getElementsByTagName('head')[0].appendChild(link);


        }else{
          setRender(false);
          setError(true);
        }
        
      })()
      
    }else{
      setRender(false);
      setError(true);
    }
    
  },[reference])

  //ottengo i dati pagine
  useEffect(()=>{
    if(!appToken) return;

    (async ()=>{
      const res = await ApiCall(`get_pages/?{"token_key":"${appToken}","updated_at":null}`);
      
      if(res.apps_pages){


        var registerPageData = res.apps_pages.find(item => item.url === "widget://smartcard_registrati" ? item.url === "widget://smartcard_registrati" : item.widget === "registrati");
        var loginPageData = res.apps_pages.find(item => item.url === "widget://smartcard_accedi" ? item.url === "widget://smartcard_accedi" : item.widget === "accedi");
        var cardPageData = res.apps_pages.find(item => item.url === "widget://smartcard_fidelity" ? item.url === "widget://smartcard_fidelity" : item.widget === "conto");

        setRegisterPage(registerPageData);
        setLoginPage(loginPageData);
        setCardPage(cardPageData);
        
        if(!cardPageData){
          setRender(false);
          setError(true);
        }else{
          setRender(true);
        }

      }else{
        console.log(res);
      }
     
    })()

  },[appToken])


  return (
    <BrowserRouter>
      <Context.Provider 
        value={{
          appButtonContentColor,
          appBackgroundColor,
          appLogoHorizontal,
          appSecondaryColor,
          topBarTextColor,
          appPrimaryColor,
          appButtonColor,
          privacyPolicy,
          smsActivation,
          registerPage,        
          linkAndroid,
          usePassword,
          deviceType,
          hideFields,
          reference,
          loginPage,
          cardPage,
          appToken,
          referral,
          baseUrl,
          linkIos,
          appLogo,
          appName,
        }}
      >
      { render ? 
        <Routes>
          <Route path='/smart-card' element={<SmartCard/>}/>
          <Route path='/register' element={<Register/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/404' element={<Page404/>}/>
          {/* <Route path='/' element={loginPage.position > registerPage.position ? <Register/> : <Login/>}/> */}
          <Route path='/' element={<Register/>}/>
        </Routes>
      :
      !render && error ? 
        <Page404/>
      :
        <div className='w-100 vh-100 flex justify-center align-center'>
          <Spin size="large" />
        </div>
      }
      </Context.Provider>
    </BrowserRouter>
  );
}

export default App;
