import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Col, ConfigProvider, Row } from 'antd';
import {
  LogoutOutlined,
  CreditCardOutlined,
  GiftOutlined
} from '@ant-design/icons';

import AppLayout from '../components/AppLayout';
import { Context } from '../context';
import Barcode from 'react-barcode';

function SmartCard(props) {

  const [customerData, setCustomerData] = useState({});
  const [customerCard, setCustomerCard] = useState({});
  const [userData, setUserData] = useState({});
  const [render, setRender] = useState(false);

  const {reference, cardPage, baseUrl, deviceType, linkAndroid, linkIos} = useContext(Context);
  const navigate = useNavigate();

  useEffect(()=>{
    
    if(!localStorage.getItem(reference)){
      navigate("/");
    }else{

        setUserData(JSON.parse(localStorage.getItem(reference)));
        setCustomerData(JSON.parse(localStorage.getItem(reference)).apps_customers);
        setCustomerCard(JSON.parse(localStorage.getItem(reference)).apps_customers_cards[0]);
  
        setRender(true);
    }

  },[])

  const logout = () => {
    localStorage.removeItem(reference);
    navigate("/?app=" + reference)
  }

  return (
    <>
    {render &&
      <AppLayout pageTitle={cardPage.title}>
        {cardPage.media &&
          <div className='page-cover' style={{backgroundImage:`url(${baseUrl + cardPage.media})`}}/>
        }
        <div className='w-100 flex justify-center align-center p-10'>
          <div 
            className='card-wrapper w-100 flex column'
            style={{marginTop:!cardPage.media && "0px"}}
          >
            <div className='card-header fs-18 bold w-100 flex space-between'>
              <Row align={"center"} className='w-100' gutter={[15,15]}>
                <Col span={12} className='ant-sm-col-24'>
                  <div className='px-10 border-1 radius-1 p-10'>{customerData.name + " " + customerData.surname}</div>
                  <a href={deviceType !== "iPhone" && deviceType !== "iPad" && deviceType !== "Macintosh" ? linkAndroid : linkIos} target='_blank'>
                    <Button className='no-shadow mt-10' size='large' type='primary' icon={<CreditCardOutlined />} >Saldo</Button>
                  </a>
                </Col>

                <Col span={12} align="end" className='ant-sm-col-24 sm-text-start'>
                  <div className='card-ecn w-100'>
                    <div className='px-10 border-1 radius-1 p-10 text-end sm-text-start'> N° {customerCard.ecn}</div>
                  </div>
                  <a className='w-100' href={deviceType !== "iPhone" && deviceType !== "iPad" && deviceType !== "Macintosh" ? linkAndroid : linkIos} target='_blank'>
                    <Button className='no-shadow mt-10' size='large' type='primary' icon={<GiftOutlined />} >Coupons</Button>
                  </a>
                </Col>

              </Row>
            </div>
            <div className='card-code-wrapper bg-white p-10 mt-20 border-radius radius-2 overflow-hidden'>
              <Barcode value={customerCard.ean13}/>
            </div>
          </div>
        </div>
        <div className='p-10'>
          <Button  size='large' block icon={<LogoutOutlined />} onClick={logout} danger>Logout</Button>
        </div>
      </AppLayout>
    }
    </>
  );
}

export default SmartCard;