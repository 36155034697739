import React from 'react';

import { Result } from 'antd';

function Page404(props) {
  return (
    <Result status="404" title="404" subTitle="Mi spiace, sembra che tu non abbia l'abilitazione ad accedere a quest'area"/>
  );
}

export default Page404;