import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  message,
  Button,
  Divider,
  Form,
  Input
} from 'antd';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import AppLayout from '../components/AppLayout';
import { ApiCallGet } from '../helpers/ApiCallGet';
import { Context } from '../context';

function Login(props) {

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);

  const {reference, referral, appToken, loginPage, baseUrl} = useContext(Context);
  const navigate = useNavigate();


  const error = (e) => {
    messageApi.open({
      type: 'error',
      content: e,
    });
  };
  const load = () => {
    messageApi.open({
      type: 'loading',
      content: 'Accesso in corso...',
      duration: 0,
    });
  };

  const login = (values) => {

    (async () => {
      const res = await ApiCallGet(`get_customer/?{"token_key":"${appToken}","email":"${values.email}","uid_device":"","password":"${values.password}"}`);
 
      if(!res.status){
        
        localStorage.setItem(reference, JSON.stringify(res));
        if(referral){
          window.open(base64_decode(referral) + `token_user=${res.apps_customers.token_user}&name=${res.apps_customers.name}&surname=${res.apps_customers.surname}&card=${res.apps_customers_cards[0].ean13}&token_key=${appToken}`,`_self`);
        }else{
          setTimeout(()=>{
            window.location.reload();
          },100)
        }
      }else{
        console.log(res);
        setDisabled(false);
        messageApi.destroy();
        error("Dati errati o utente non esistente");
      }
    
    })()

  };

  useEffect(()=>{
    if(referral) return;
    if(localStorage.getItem(reference)) navigate("/smart-card")
  },[reference])


  return (
    <AppLayout pageTitle={loginPage.title}>
      {loginPage.media &&
        <div className='page-cover' style={{backgroundImage:`url(${baseUrl + loginPage.media})`}}/>
      }
      <div className='w-100 flex justify-center align-center'>
        <div 
          className='form-wrapper'
          style={{margin:!loginPage.media && "0px"}}
        >
          {loginPage.body &&
            <div className='mb-20' dangerouslySetInnerHTML={{ __html: loginPage.body }}/>
          }
            <Divider/>

            <Form
              form={form}
              name="login"
              className='login-form'
              onFinish={(e) => {
                login(e);
                setDisabled(!disabled);
                load();
              }}
              layout='vertical'
              scrollToFirstError
            >
              {contextHolder}
              {/* email */}
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'Il campo E-Mail non è valido',
                  },
                  {
                    required: true,
                    message: 'Inserire una E-mail',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* email */}
          
              {/* password */}
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Si prega di inserire una password',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              {/* password */}  
          
              <Form.Item>
                <Button disabled={disabled} shape='round' type="primary" block size='large' className='fs-20 bold mt-20' htmlType="submit">
                  Accedi
                </Button>
              </Form.Item>
            </Form>

            <Divider/>
            <div className='w-100 text-center fs-15'> 
              Non hai un account? 
              <a 
                className='bold' 
                onClick={() => {
                  navigate(`/register?webhook=${referral}`);
                }}
              >
                Registrati
              </a>
            </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default Login;