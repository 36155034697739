import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
} from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import locale from 'antd/locale/it_IT';
import { v4 as uuidv4 } from 'uuid';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import AppLayout from '../components/AppLayout';
import { ApiCall } from '../helpers/ApiCall';
import { Context } from '../context';
import { ApiCallGet } from '../helpers/ApiCallGet';
const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';


function dateNum(num){
  num = "0"+ num.toString();
  return num.substr(-2);
}

function Register(props) {

  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [province, setProvince] = useState([]);
  const [regioni, setRegioni] = useState([]);
  const [comuni, setComuni] = useState([]);
  const [caps, setCaps] = useState([]);

  //settaggio campi form
  const [birthData, setBirthData] = useState("");
  const [provincia, setProvincia] = useState("");
  const [regione, setRegione] = useState("");
  const [comune, setComune] = useState("");
  const [cap, setCap] = useState("");
  const [form] = Form.useForm();

  const {
    privacyPolicy, 
    smsActivation,
    registerPage, 
    usePassword,
    deviceType, 
    hideFields, 
    reference, 
    referral, 
    appToken, 
    baseUrl
  } = useContext(Context);
  const navigate = useNavigate();


  const changeData = (e) => {
    setBirthData(`${e.$y}-${dateNum(e.$M + 1)}-${dateNum(e.$D)}`)
  }

  const error = (e) => {
    messageApi.open({
      type: 'error',
      content: e,
    });
  };
  const load = () => {
    messageApi.open({
      type: 'loading',
      content: 'Registrazione in corso...',
      duration: 0,
    });
  };


  useEffect(()=>{
    if(referral) return;
    if(localStorage.getItem(reference)) navigate("/smart-card")
  },[])

  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const register = (values) => {
   
    const body = 
      {
        "token_user": "",
        "parameters": {
          "token_key": appToken,
          "reference": reference,
          "uid_device": uuidv4(),
          "platform": "Browser",
          "platform_version": "1.0",
          "last_coordinates": "",
          "device_model": deviceType ? deviceType : "-",
          "name": values.name ? values.name : "-",
          "surname":  values.surname ? values.surname : "-",
          "city": values.comune ? values.comune : "-",
          "address": values.address ? values.address : "-",
          "zip_code": values.zip_code ? values.zip_code : "-",
          "province":  values.provincia ? values.provincia : "-",
          "region": values.region ? values.region : "-",
          "fiscal_code": values.fiscal_code ? values.fiscal_code : "-",
          "lottery_code": values.lottery_code ? values.lottery_code : "-",
          "state": "IT",
          "phone":  values.phone ? values.phone.toString() : "-",
          "mobile": values.mobile ? values.mobile.toString() : "-",
          "email": values.email ? values.email : "-",
          "password": values.password ? values.password : values.email,
          "gender": values.gender ? values.gender : "Altro",
          "birth_data": birthData ? birthData : "1980-01-01",
          "consent_marketing":values.consent_marketing ? "1" : "0",
          "consent_third_parties_marketing": values.consent_third_parties_marketing ? "1" : "0"
        }
      };

      (async () => {
        const res = await ApiCall(`smartcard_set_customer/index.php?method=${values.sms_activation}`, JSON.stringify(body));
          
        if(!res.status){
            localStorage.setItem(reference, JSON.stringify(res));
            if(referral){
              window.open(base64_decode(referral) + `token_user=${res.apps_customers.token_user}&name=${res.apps_customers.name}&surname=${res.apps_customers.surname}&card=${res.apps_customers_cards[0].ean13}&token_key=${appToken}`,`_self`);
            }else{
              setTimeout(()=>{
                window.location.reload();
              },100)
            }
          }else{
            if(res.message == "not_enabled"){
              error("Utente non abilitato")
            }else if(res.status == "Already registered" && usePassword){
              (async () => {
                const res = await ApiCallGet(`get_customer/?{"token_key":"${appToken}","email":"${values.email}","uid_device":"","password":"${values.email}"}`);
                
                if(!res.status){
                  localStorage.setItem(reference, JSON.stringify(res));
                  if(referral){
                    window.open(base64_decode(referral) + `token_user=${res.apps_customers.token_user}&name=${res.apps_customers.name}&surname=${res.apps_customers.surname}&card=${res.apps_customers_cards[0].ean13}&token_key=${appToken}`,`_self`);
                  }else{
                    setTimeout(()=>{
                      window.location.reload();
                    },100)
                  }
                }else{
                  setDisable(false);
                  console.log(res);
                  messageApi.destroy();
                  error("Dati errati o utente non esistente");
                }
              
              })()
            }else{
              setDisable(false);
              messageApi.destroy();
              error("Utente già esistente");
            };
            console.log(res);
          }
      })()

  };

  //scarico le regioni 
  useEffect(()=>{
    (async () => {
      const res = await ApiCall("get_comuni/");
      
      if(res){
        var regioniTemp = [];
        
        res.forEach(regione => {
          regioniTemp.push({
            label: regione,
            value: regione
          })
        });

        setRegioni(regioniTemp);
      }
    })()
  },[])

  //scarico le province 
  useEffect(()=>{

    if(!regione) return;

    (async () => {
      const res = await ApiCall(`get_comuni/?regione=${regione}`);
      
      if(res){
        res.forEach(provincia => {
          provincia["label"] = provincia["provincia"] + " - " + provincia["provincia_sigla"] 
          provincia["value"] = provincia["provincia_sigla"] 
          delete provincia["provincia"] 
          delete provincia["provincia_sigla"] 
        });

        setProvince(res);
      }
    })()
  },[regione])

  //scarico i comuni
  useEffect(()=>{
    
    if(!provincia) return;
    
    (async () => {
      const res = await ApiCall(`get_comuni/?provincia=${provincia}`);
      
      if(res){
        var comuniTemp = [];
        
        res.forEach(comune => {
          comuniTemp.push({
            label: comune,
            value: comune
          })
        });

        setComuni(comuniTemp);
      }
    })()

  },[provincia])

  //scarico i cap
  useEffect(()=>{
   
    if(!comune) return;

    (async () => {
      const res = await ApiCall(`get_comuni/?comune=${comune}`);
      
      if(res){
        var capsTemp = [];
        
        res.forEach(cap => {
          capsTemp.push({
            label: cap,
            value: cap
          })
        });

        setCaps(capsTemp);
      }
    })()
  },[comune])
  
  return (
   <AppLayout pageTitle={registerPage.title}>
    {contextHolder}
    {registerPage.media &&
      <div className='page-cover' style={{backgroundImage:`url(${baseUrl + registerPage.media})`}}/>
    }
    <div className='w-100 flex justify-center align-center'>
      <div 
        className='form-wrapper'
        style={{margin:!registerPage.media && "0px"}}
      >
        {registerPage.body &&
          <div className='mb-20' dangerouslySetInnerHTML={{ __html: registerPage.body }}/>
        }
          <Divider/>
          <Form
            form={form}
            name="register"
            className='register-form'
            onFinish={(e) => {
              register(e);
              load();
              setDisable(!disable);
            }}
            layout='vertical'
            scrollToFirstError
          >
        
            {!JSON.parse(hideFields).name &&
              <>
                {/* cognome */}
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[
                    {
                      required: true,
                      message: 'Inserire il Nome',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {/* cognome */}
              </>
            }
        
            {!JSON.parse(hideFields).surname &&
              <>
                {/* cognome */}
                <Form.Item
                  name="surname"
                  label="Cognome"
                  rules={[
                    {
                      required: true,
                      message: 'Inserire il Cognome',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {/* cognome */}
              </>
            }
        
        
            {!JSON.parse(hideFields).birth_data &&
              <>
                {/* data di nascita */}
                <Form.Item 
                  name="birth_data" 
                  label="Data di Nascita"
                >
                  <ConfigProvider locale={locale}>
                    <DatePicker format={dateFormat} className='w-100' onChange={changeData}/>
                  </ConfigProvider>
                </Form.Item>
                {/* data di nascita */}
              </>
            }
          
        
            {/* email */}
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'Il campo E-Mail non è valido',
                },
                {
                  required: true,
                  message: 'Inserire una E-mail',
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* email */}
        
            {!usePassword &&
            <>
              {/* password */}
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Si prega di inserire una password',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              {/* password */}  
          
              {/* conferma password */}
              <Form.Item
                name="confirm"
                label="Conferma Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Conferma la password',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('La Password sembra non corrispondere'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              {/* conferma password */}
            </>
            }
            
        
            {!JSON.parse(hideFields).mobile &&
              <>
                {/* cellulare */}
                <Form.Item
                  name="mobile"
                  label="Cellulare"
                  rules={[
                    {
                      required: true,
                      message: 'Inserire il numero di cellulare',
                    },
                  ]}
                >
                  <InputNumber
                    //addonBefore={mobilePrefixSelector}
                    style={{
                      width: '100%',
                    }}
                    controls={false}
                  />
                </Form.Item>
                {/* cellulare */}
              </>
            }
           
        
            {!JSON.parse(hideFields).phone &&
              <>
                {/* telefono */}
                <Form.Item
                  name="phone"
                  label="Telefono"
                  rules={[
                    {
                      type:"number",
                      required: true,
                      message: 'Inserire il numero di telefono',
                    },
                  ]}
                >
                  <InputNumber
                    //addonBefore={phonePrefixSelector}
                    style={{
                      width: '100%',
                    }}
                    controls={false}
                  />
                </Form.Item>
                {/* telefono */}
              </>
            }
        
            {!JSON.parse(hideFields).gender &&
              <>
              {/* genere */}
              <Form.Item
                name="gender"
                label="Genere"
                rules={[
                  {
                    required: true,
                    message: 'Seleziona il tuo genere',
                  },
                ]}
              >
                <Select placeholder="Seleziona il tuo genere">
                  <Option value="Uomo">Uomo</Option>
                  <Option value="Donna">Donna</Option>
                  <Option value="other">Altro</Option>
                </Select>
              </Form.Item>
              {/* genere */}
              </>
            }
            
        
          { !JSON.parse(hideFields).lottery_code &&
            <>
              {/* codice lotteria */}
              <Form.Item
                name="lottery_code"
                label="Codice Lotteria"
              >
                <Input />
              </Form.Item>
              {/* codice lotteria */}
            </>
          }
        
          { !JSON.parse(hideFields).fiscal_code &&
              <>
                {/* codice fiscale */}
                  <Form.Item
                    name="fiscal_code"
                    label="Codice Fiscale"
                  >
                    <Input />
                  </Form.Item>
                {/* codice fiscale */}
              </>
            }
        
            {!JSON.parse(hideFields).address &&
              <>
                {/* regioni */}
                <Form.Item
                  name="region"
                  label="Regione"
                  rules={[
                    {
                      required: true,
                      message: 'Seleziona la regione',
                    },
                  ]}
                >
                  <Select options={regioni} onChange={(e)=>setRegione(e)}/>
                </Form.Item>
                {/* regioni */}
                
                {province.length > 0 &&
                  <>
                    {/* provincia */}
                    <Form.Item
                      name="provincia"
                      label="Provincia"
                      rules={[
                        {
                          required: true,
                          message: 'Seleziona la provincia',
                        },
                      ]}
                    >
                      <Select options={province} onChange={(e)=>setProvincia(e)}/>
                    </Form.Item>
                    {/* provincia */}
                  </>
                }
        
                {comuni.length > 0 &&
                  <>
                    {/* comune */}
                    <Form.Item
                      name="comune"
                      label="Comune"
                      rules={[
                        {
                          required: true,
                          message: 'Seleziona il tuo Comune',
                        },
                      ]}
                    >
                      <Select options={comuni} onChange={(e)=>setComune(e)}/>
                    </Form.Item>
                    {/* comune */}
                  </>
                }
        
                {caps.length > 0 &&
                  <>
                    {/* cap */}
                    <Form.Item
                      name="zip_code"
                      label="CAP"
                      rules={[
                        {
                          required: true,
                          message: 'Seleziona il CAP',
                        },
                      ]}
                    >
                      <Select options={caps} onChange={(e)=>setCap(e)} />
                    </Form.Item>
                    {/* cap */}
                  </>
                }

                {cap &&
                  <>
                    {/* indirizzo */}
                    <Form.Item
                      name="address"
                      label="Indirizzo"
                      rules={[
                        {
                          required: true,
                          message: 'Inserisci il tuo indirizzo',
                        },
                      ]}
                    >
                      <Input/>
                    </Form.Item>
                    {/* indirizzo */}
                  </>
                }
               
              </>
            }
            <Divider/>

            {!JSON.parse(hideFields).terms &&
              <>
                {/* spunte di marketing */}
                <Form.Item
                  name="consent_marketing"
                  valuePropName="checked"
                >
                  <Checkbox>
                    Acconsento al trattamento dei dati per finalità di marketing
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  name="consent_third_parties_marketing"
                  valuePropName="checked"
                >
                  <Checkbox>
                    Acconsento al trattamento dei dati per finalità di marketing per terze parti
                  </Checkbox>
                </Form.Item>

                {/* spunte di marketing  */}
              </>
            }

            <>
              {/* privacy */}
              <Form.Item
                name="terms"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error('Devi accettare i Termini e Condizioni')),
                  },
                ]}
              >
                <Checkbox>
                  Ho letto ed accetto i <span onClick={showModal} className='color-blue'>Termini e Condizioni</span>
                </Checkbox>
              </Form.Item>
              {/* privacy */}
            </>
                
            {smsActivation == "1" && !JSON.parse(hideFields).phone &&
              <>
                {/* attivazione sms */}
                <Form.Item
                  name="sms_activation"
                  valuePropName="checked"
                >
                  <Checkbox>
                    Invia card anche via SMS
                  </Checkbox>
                </Form.Item>
                {/* attivazione sms */}
              </>
            }
            
              
            <Form.Item>
              <Button disabled={disable} shape='round' type="primary" block size='large' className='fs-20 bold mt-10' htmlType="submit">
                Registrati
              </Button>
            </Form.Item>
          </Form>

          <Divider/>
          <div className='w-100 text-center fs-15'> 
            Possiedi già un account? 
            <a 
              className='bold'  
              onClick={() => {
                navigate(`/login?webhook=${referral}`);
              }}
            >
              Accedi
            </a>
          </div>
      </div>
    </div>

    <Modal title="Privacy Policy" width={"1500px"} open={isModalOpen} onCancel={handleCancel} onOk={handleOk}>
      <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}/>
    </Modal>

    
   </AppLayout>
  );
}

export default Register;