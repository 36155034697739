// componente utilizzato per chiamare i dati verso l'esterno da next.js 
// al server dati

const endpoint = "https://app.easyappear.it/webservice";

const ApiCall = async (path, body) => {
  try{
    const response = 
      await fetch(
        `${endpoint}/${path}`,
        {
          method: "POST",
          body
        }
      );
    if(response){

      const json = await response.json();
      if(json){
        return json;    
      }else{
        console.log(json.code, json.message, json.status); 
      }
    }

  }catch(_){}
  
  return null;
}

export {ApiCall};