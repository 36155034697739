// componente utilizzato per chiamare i dati verso l'esterno da next.js 
// al server dati

const endpoint = "https://app.easyappear.it/webservice";

const ApiCallGet = async (path) => {

  try{
    const response = 
      await fetch(
        `${endpoint}/${path}`,
        {
          method: "GET",
        }
      );
    if(response){
      const json = await response.json();
      
      if(json){
        return json;    
      }else{
        console.log(json.code, json.message); 
      }
    }

  }catch(_){}
  
  return null;
}

export {ApiCallGet};